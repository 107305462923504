import Layout from '@/layout'

const villageRouter = {
  path: '/register',
  component: Layout,
  children: [{
    path: '/',
    name: 'register',
    meta: {
      title: "防疫排查"
    },
    component: () =>
      import('@/views/fangyi/register.vue')
  }, {
    path: '/register/luo',
    name: 'luo',
    meta: {
      title: "返洛人员登记"
    },
    component: () =>
      import('@/views/fangyi/luo.vue')
  }, {
    path: '/register/yimiao',
    name: 'yimiao',
    meta: {
      title: "疫苗接种排查"
    },
    component: () =>
      import('@/views/fangyi/yimiao.vue')
  }, {
    path: '/register/newAdd',
    name: 'newAdd',
    meta: {
      title: "居民信息登记"
    },
    component: () =>
      import('@/views/fangyi/newAdd.vue')
  }, {
    path: '/register/nATest',
    name: 'nATest',
    meta: {
      title: "核酸检测报备"
    },
    component: () =>
      import('@/views/fangyi/nATest.vue')
  }, {
    path: '/shop/myShop',
    name: 'myShop',
    meta: {
      title: "服务商家"
    },
    component: () =>
      import('@/views/shop/myShop.vue')
  }, {
    path: '/shop/myShop/addShop/:shopId/:add/:shopCate',
    name: 'addShop',
    meta: {
      title: "商铺信息"
    },
    component: () =>
      import('@/views/shop/addShop.vue')
  }, {
    path: '/shop/myShop/myShopOrder/:shopId',
    name: 'myShop',
    meta: {
      title: "我的订单"
    },
    component: () =>
      import('@/views/shop/myShopOrder/index.vue')
  }, {
    path: '/shop/myShop/myShopOrder/orderXq/:OId/:shopId',
    name: 'orderXq',
    meta: {
      title: "详情"
    },
    component: () =>
      import('@/views/shop/myShopOrder/orderXq.vue')
  }, {
    path: '/shop/myEnt',
    name: 'myEnt',
    meta: {
      title: "我的企业"
    },
    component: () =>
      import('@/views/shop/myEnt.vue')
  }, {
    path: '/shop/myShop/addEnt/:shopId/:add',
    name: 'addEnt',
    meta: {
      title: "企业信息"
    },
    component: () =>
      import('@/views/shop/addEnt.vue')
  }, {
    path: '/shop/myShop/ygList/:seId/:ekind',
    name: 'ygList',
    meta: {
      title: "员工信息"
    },
    component: () =>
      import('@/views/shop/yuangong/ygList.vue')
  }, {
    path: '/shop/myShop/ygList/addYg/:seId/:ekind/:add',
    name: 'addYg',
    meta: {
      title: "员工信息"
    },
    component: () =>
      import('@/views/shop/yuangong/addYg.vue')
  }, {
    path: '/shop/myShop/goodsList/:id/:shopCate',
    name: 'goodsList',
    meta: {
      title: "商品列表"
    },
    component: () =>
      import('@/views/shop/goodsList.vue')
  }, {
    path: '/shop/myShop/Promotion/:id',
    name: 'Promotion',
    meta: {
      title: "促销活动"
    },
    component: () =>
      import('@/views/shop/Promotion.vue')
  }, {
    path: '/shop/myShop/goodsList/goodsAdd/:id/:shopId/:goodsId',
    name: 'goodsAdd',
    meta: {
      title: "商品信息"
    },
    component: () =>
      import('@/views/shop/goodsAdd.vue')
  }, {
    path: '/shop/myShop/Promotion/:id',
    name: 'Promotion',
    meta: {
      title: "促销活动"
    },
    component: () =>
      import('@/views/shop/Promotion.vue')
  }, {
    path: '/shop/myShop/Promotion/promotionAdd/:id/:PromotionId/:shopId',
    name: 'promotionAdd',
    meta: {
      title: "促销活动"
    },
    component: () =>
      import('@/views/shop/promotionAdd.vue')
  }, {
    path: '/shop/myShop/goodsTypeList/:shopId',
    name: 'goodsAdd',
    meta: {
      title: "商品分类"
    },
    component: () =>
      import('@/views/shop/goodsTypeList.vue')
  }, {
    path: '/shop/myShop/goodsTypeList/goodsTypeListAdd/:id/:SortId/:shopId',
    name: 'goodsTypeListAdd',
    meta: {
      title: "商品分类"
    },
    component: () =>
      import('@/views/shop/goodsTypeListAdd.vue')
  }, {
    path: '/jifenShop/personal',
    meta: {
      title: "我的订单"
    },
    component: () =>
      import('@/views/fivestar/jifenShop/personal.vue')
  }, {
    path: '/shop/myShop/Promotion/PromotionPenple/:id',
    name: 'PromotionPenple',
    meta: {
      title: "报名信息"
    },
    component: () =>
      import('@/views/shop/PromotionPenple.vue')
  },
  {
    path: '/register/fangyi/yimiaoNew/ymShouye',
    name: 'ymShouye',
    meta: {
      title: "疫苗接种登记"
    },
    component: () =>
      import('@/views/fangyi/yimiaoNew/ymShouye.vue')
  },
  {
    path: '/register/fangyi/yimiaoNew/ymShouye/ymYou/:hid',
    name: 'ymYou',
    meta: {
      title: "疫苗接种登记"
    },
    component: () =>
      import('@/views/fangyi/yimiaoNew/ymYou.vue')
  },
  {
    path: '/register/fangyi/yimiaoNew/ymShouye/ymWu',
    name: 'ymWu',
    meta: {
      title: "疫苗接种登记"
    },
    component: () =>
      import('@/views/fangyi/yimiaoNew/ymWu.vue')
  },]
}

export default villageRouter