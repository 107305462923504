/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout'

const router = {
  path: '/ylOrder',
  component: Layout,
  children: [{
    path: '',
    name: 'ylOrder',
    meta: {
      title: "养老美团"
    },
    component: () =>
      import('@/views/ylOrder/index.vue')
  }, {
    path: '/ylOrder/orderList',
    name: 'orderList',
    meta: {
      title: "订单列表"
    },
    component: () =>
      import('@/views/ylOrder/orderList.vue')
  }, {
    path: '/ylOrder/orderList/orderMy/:OId',
    name: 'orderMy',
    meta: {
      title: "订单详情"
    },
    component: () =>
      import('@/views/ylOrder/orderMy.vue')
  }, {
    path: '/ylOrder/ylOrder/orderData/:SId',
    name: 'orderData',
    meta: {
      title: "养老服务包详情"
    },
    component: () =>
      import('@/views/ylOrder/orderData.vue')
  }, {
    path: '/ylOrder/currentOrder/:Id/:Code',
    name: 'currentOrder',
    meta: {
      title: "服务项目"
    },
    component: () =>
      import('@/views/ylOrder/currentOrder.vue')
  }, {
    path: '/ylOrder/orderList/appraise/:Id',
    name: 'appraise',
    meta: {
      title: "评价"
    },
    component: () =>
      import('@/views/ylOrder/appraise.vue')
  }, {
    path: '/ylOrder/ylOrder/currentOrder/servicetOfMan/:Id',
    name: 'servicetOfMan',
    meta: {
      title: "商品详情"
    },
    component: () =>
      import('@/views/ylOrder/servicetOfMan.vue')
  }, {
    path: '/huayang/serve',
    name: 'serve',
    meta: {
      title: "资源看板"
    },
    component: () =>
      import('@/views/huayang/serve.vue')
  }, {
    path: '/huayang/comment',
    name: 'comment',
    meta: {
      title: "服务评价"
    },
    component: () =>
      import('@/views/huayang/comment.vue')
  }, {
    path: '/huayang/firm',
    name: 'firm',
    meta: {
      title: "企业端"
    },
    component: () =>
      import('@/views/huayang/firm.vue')
  }, {
    path: '/huayang/resource',
    name: 'resource',
    meta: {
      title: "发布资源清单"
    },
    component: () =>
      import('@/views/huayang/resource.vue')
  }, {
    path: '/huayang/interaction/suggest',
    name: 'suggest',
    meta: {
      title: "区企互动记录"
    },
    component: () =>
      import('@/views/huayang/suggest.vue')
  }, {
    path: '/home',
    name: 'home',
    meta: {
      title: "首页"
    },
    component: () =>
      import('@/views/huayang/home.vue')
  },]
}

export default router