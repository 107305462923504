/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout'

const villageRouter = {
  path: '/wangge',
  component: Layout,
  children: [{
    path: '',
    name: 'wangge',
    meta: {
      title: "小区管理"
    },
    component: () =>
      import('@/views/wangge/xiaoqu.vue')
  }, {
    path: '/wangge/danyuan/:BId/:GId/:RId',
    name: 'danyuan',
    meta: {
      title: "单元"
    },
    component: () =>
      import('@/views/wangge/danyuan.vue')
  },
  {
    path: '/wangge/danyuan/jiating/:BRId/:ShopShow/:RId/:Code/:BFId/:BId/:BUId',
    name: 'jiating',
    meta: {
      title: "家庭列表"
    },
    component: () =>
      import('@/views/wangge/jiating.vue')
  },
  {
    path: 'jiating/:Id',
    name: 'jiatingAdd',
    meta: {
      title: "家庭列表"
    },
    component: () =>
      import('@/views/wangge/jiating.vue')
  },
  {
    path: 'jtbianji/:Id',
    name: 'jtbianji',
    meta: {
      title: "家庭成员编辑"
    },
    component: () =>
      import('@/views/wangge/jtbianji.vue')
  },
  {
    path: 'xqbianji/:Id',
    name: 'xqbianji',
    meta: {
      title: "小区编辑"
    },
    component: () =>
      import('@/views/wangge/xqbianji.vue')
  },
  {
    path: 'chengyuanXq/:Id',
    name: 'chengyuanXq',
    meta: {
      title: "详情"
    },
    component: () =>
      import('@/views/wangge/chengyuanXq.vue')
  },
  {
    path: 'danyuan/addroom/:Id',
    name: 'roomAdd',
    meta: {
      title: "保存房屋"
    },
    component: () =>
      import('@/views/wangge/roomAdd.vue')
  },
  ]
}

export default villageRouter